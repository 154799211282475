.underline {
    width: 100%;
    border-bottom: 0.1px solid rgb(19, 18, 18); /* This creates a white line */
    margin-bottom: 1rem;
  }
  
  .endLine {
    font-size: 20px;
  color: #fff;
  text-align: center; /* Center the text */
  font-weight: bold;
  margin-top: 10rem;
  margin-bottom: 10rem;
  }
  