body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .container {
    text-align: center;
   
  }
  
  
  .image-row {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .image-container {
    width: 30%;
  }
  
  .image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
 
  
  
  
  @media (max-width: 768px) {
    .image-row {
      flex-direction: column;
      gap: 20px;
    }
  
    .image-container {
      width: 100%;
    }
  }
  