/* navBar.css */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
}

.header{
 
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
 
}


.logo-container {
 margin-left: 45rem;
  }

.logo {
  height: 60px; 
  margin-left: 2.5rem;
  }

.logo-text {
  color: #ff6600; /* Orange text for logo */
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 10px;
  margin-top: -2px;
}


.navbar a{
  position: relative;
  font-size: 18px;
  color:gray;
  font-weight: 500;
  text-decoration: none;
  margin-left: 40px;
}

.navbar a::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 2px;
  background-color:  #ff6600;
  transition: .3s;
}
.navbar a:hover::before{
 width: 100%;
}

.menubar_bgcolor {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff6600; /* Orange background */
  width: 3rem;
  height: 3rem;
  border-radius: 50%; /* Makes it circular */
  margin-right: 20px; /* Adjust margin as needed */
}

.menubar-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff; /* White icon color */
  font-size: 1.5rem; /* Adjust icon size */
}

.menubar-link:hover {
  color: #000; /* Black text on hover */
}

@media screen and (max-width: 768px) {
  .navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 90px;
    right: -300px;
    width: 300px;
    height: 100vh;
    background: #2a3239;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out /* Adjusted padding for responsiveness */
  }

  .logo-container {
    margin: 0; /* Reset margin for centered alignment */
    justify-content: center;
    margin-bottom: 20px; /* Adjust spacing */
    
  }

  .menu-container {
    margin-left: 0; /* Reset margin for centered alignment */
    justify-content: center;
  }

  .menu {
    flex-direction: column; /* Stack menu items vertically */
    align-items: center;
  }

  .menu li {
    margin-bottom: 10px; /* Adjust spacing between menu items */
  }
  #mobile {
    display:flex;
  }

  #mobile i {
    font-size: 24px;
    cursor: pointer;
  }
}