.sticky-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; /* Adjust as needed */
}

.sticky-button {
    display: block;
    width: 50px; /* Adjust width and height as needed */
    height: 50px;
    text-align: center;
    line-height: 50px;
    margin-bottom: 10px; /* Adjust spacing between buttons */
    border-radius: 50%;
    color: #fff;
    font-size: 24px;
    transition: transform 0.3s ease-in-out;
}

.sticky-button.instagram {
    background-color: #ee2a7b; /* Instagram color */
}

.sticky-button.facebook {
    background-color: #5851DB; /* Facebook color */
}

.sticky-button.whatsapp {
    background-color: #25d366; /* WhatsApp color */
}

.sticky-button:hover {
    transform: scale(1.1); /* Adjust scale as desired */
}
