/* CSS for Swiper Slider */

/* Set basic styles for the body */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #000; /* Set background color to black */
}

/* Container styles */
.container {
  width: 100%;
  padding: 20px;
}

/* Heading styles */
.heading {
  font-size: 2rem;
  margin-bottom: 2rem; /* Adjusted margin */
  color: #fff; /* Change heading color to white for contrast */
  text-align: left; /* Align text to the left */
  margin-left: -95rem;
}

/* Swiper Container styles */
.swiper_container {
  width: 100%;
  height: 30rem;
  padding-top: 2.5rem;
  padding-bottom: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* General Swiper Slide Styling */
.swiper-slide {
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 6px; 
 /* Adjusted margin */
}

.swiper-slide img {
  width: 100%; /* Make the image fill its parent slide */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
  
}






/* Styling for Phones */
@media (max-width: 868px) {
  .swiper-slide {
    width: 100%; /* Full width on phones */
    height: 200px; /* Adjusted height for phones */
  }
}

/* Styling for Desktops */
@media (min-width: 869px) {
  .swiper-slide {
    width: 250px; /* Adjusted width for desktops */
    height: 250px; /* Adjusted height for desktops */
  }
}

/* Slider controller styles */
.slider-controller {
  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: center; /* Center align horizontally */
  align-items: center; /* Center align vertically */
}

/* Slider arrow styles */
.slider-arrow {
  color: #fff; /* Change arrow color to white for contrast */
  font-size: 1.5rem; /* Adjust arrow size */
  cursor: pointer;
  margin: 0 10px; /* Space between arrows */
}

/* Swiper navigation button styles */
.swiper-button-prev,
.swiper-button-next {
  width: 20px; /* Adjusted size */
  height: 40px; /* Adjusted size */
}

/* Swiper pagination styles */
.swiper-pagination {
  bottom: 10px;
  position: absolute;
  width: 100%;
  text-align: center;
}

/* End of Swiper Slider CSS */
