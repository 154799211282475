.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    background-color: black;
  }
  
  .text {
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.5rem;
    position: absolute;
    color: white;
    margin-top: 8rem;
    margin-lift: 9rem;
  }
  
  .images {
    display: flex;
    justify-content: center;
    gap: 10rem;
    margin-bottom: 20px;
  }
  
  .image {
    width: 100px;
    height: 25rem;
  }
  
  .footer {
    position: absolute;
    margin-top: 25rem;
  }
  
  .buy-button {
    padding: 10px 20px;
    font-size: 6rem;
    background-color:  #ff6600;
    color: white;
    border: none;
    cursor: pointer;
    width: 10rem;
    margin-top: 20rem;
  }
  
  .buy-button:hover {
    background-color: #ff6600;
  }
  
  /* Responsive Design */
  @media (min-width: 568px) {
    .container {
      padding: 20px;
    }
  
    .text {
      font-size: 1rem;
    }
  
    .image {
      width: 300px;
    }
  
    .buy-button {
      font-size: 1.7rem;
      
    }
  }
  