.custom-models-container {
  background-color: black;
  color: orange;
  padding: 50px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: bold;
}

.content {
  text-align: left;
 
}

p {
  font-size: 1.2em;
  margin: 20px 0;
  line-height: 1.6;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.quote-button {
  background-color:  #ff6600;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.quote-button:hover {
  background-color: darkorange;
}
