.reviews-container {
  background-color: black;
  color: white;
  padding: 20px;
  text-align: left;
}

h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.review {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.review.left {
  justify-content: flex-start;
}

.review.right {
  justify-content: flex-end;
  margin-left: 20rem;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 20px;
}

.review-content {
  max-width: 60%;
}

.stars {
  display: flex;
  font-size: 2em;
}

.star {
  cursor: pointer;
  color: gray;
}

.star.filled {
  color: gold;
}

p {
  margin: 10px 0 0;
  font-size: 1.2em;
}