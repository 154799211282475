.container {
    margin: 0 auto;
    padding: 0 20px;
    background-color: #000;
    margin-top: -200px;
  }
  
  .our_service_title {
    
    color: #FF4500; /* Deep orange color */
    text-align: left;
    margin: 20px 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-left: -90rem;
    margin-bottom: -20px;
  }
  
  .our_service_text {
    font-family: 'Times New Roman', Times, serif;
    font-size: 2em;
    color: #fff;
    text-align:left;
    margin: 0 20px;
    font-weight: bolder;
    letter-spacing: -0.5px;
   
  }
  
  .poly_portraits {
   
    margin: 3rem;
  }
  
  .poly_portraits_text1 {
    font-size: 1.2rem;
    color: rgb(173, 170, 170);
    text-align: justify;
    margin: 0 20px;
    font-weight: bolder;
    letter-spacing: -0.5px;
   
  }
  
  .poly_portraits_text2 {
    color: rgb(184, 183, 183);
    text-align: justify;
    margin: 0 20px;
  margin-bottom: -80px;
    font-size: 1.2rem;
   
  }
  
  .question {
   
    margin: 3rem;
   
  }
  
  .question_text {
    font-size: 1.2em;
    color:  rgb(173, 170, 170); /* Deep orange color */
    text-align: justify;
    margin: 0 20px;
    font-weight: bolder;
    letter-spacing: -0.5px;
  }
  
  .answer_text {
    font-size: 1.2rem;
    color: rgb(173, 170, 170);
    text-align: justify;
    margin: 0 20px;
    letter-spacing: -0.5px;
    margin-top: 0.5rem;
  }
  
  .contact_us {
    margin-top: 1rem;
  }
  
  .contact_us_text {
    font-size: 1.2em;
    color: rgb(173, 170, 170);
    text-align: left;
    margin: 0 20px;
    font-weight: bolder;
    letter-spacing: -0.5px;
  }
  