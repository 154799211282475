.packages-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  background-color: black;
  padding: 40px;
}

.package {
  background-color: #1a1a1a;
  border: 2px solid #ffcc00;
  width: 20rem;
  height: 30rem;
  text-align: center;
  color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.package:hover {
  transform: scale(1.05);
}

.package-header {
  background-color: #ffcc00;
  color: black;
  font-weight: bold;
  padding: 15px 0;
  font-size: 1.5em;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.package-body {
  padding: 30px 20px;
  margin-top: 5rem;
}

.feature {
  height: 15px;
  background-color: gray;
  margin: 15px 0;
  border-radius: 5px;
  
}
.packages-header {
  text-align: center;
  color: #ffcc00;
  margin-bottom: 20px;
}


.package {
  background-color: #333;
  border-radius: 10px;
  padding: 10px;
  width: 20%;
}

.package-header {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.package-body .feature {
  margin: 10px 0;
}

